import request from '@/utils/request'


// 查询发票-用户开票记录列表
export function listApply(query) {
  return request({
    url: '/invoice/apply/list',
    method: 'get',
    params: query
  })
}

// 查询发票-用户开票记录分页
export function pageApply(query) {
  return request({
    url: '/invoice/apply/page',
    method: 'get',
    params: query
  })
}

// 查询发票-用户开票记录详细
export function getApply(data) {
  return request({
    url: '/invoice/apply/detail',
    method: 'get',
    params: data
  })
}

// 新增发票-用户开票记录
export function addApply(data) {
  return request({
    url: '/invoice/apply/add',
    method: 'post',
    data: data
  })
}

// 修改发票-用户开票记录
export function updateApply(data) {
  return request({
    url: '/invoice/apply/edit',
    method: 'post',
    data: data
  })
}

// 删除发票-用户开票记录
export function delApply(data) {
  return request({
    url: '/invoice/apply/delete',
    method: 'post',
    data: data
  })
}
